@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body,
html {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-family: 'Roboto';
  background-color: #000000;
  scroll-behavior: smooth;
  /* scrollbar-width: thin; */
}

#root {
  height: 100vh;
  background-color: transparent;
}
.slick-slider .slick-track {
  margin-left: 0;
}
.clear {
  clear: both;
}

.texttruncate {
  word-wrap: break-word;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-right: 0px;
  padding-left: 0px;
  /* margin-bottom: 40px; */
}
.rootContainer {
  font-family: 'Open Sans';
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // background: #fff;
  min-height: 100%;
}

.language--name {
  margin-left: 27px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

a {
  color: #337ab7;
}

.v-fa {
  width: 22px;
  min-height: 22px;
  background-size: cover;
  background-color: #fff;
  background-position: center;
  &-card {
    background-image: url(../../assets/icons/fa-card.svg);
  }
  &-card-circle {
    min-height: 25px;
  }
  &-film {
    background-image: url(../../assets/icons/film.svg);
    background-color: transparent;
    width: 24px;
  }
  &-paypal {
    background-image: url(../../assets/icons/fa-paypal.svg);
  }
}
:focus {
  outline: none;
}
.modal {
  .modal-dialog {
    pointer-events: none;
    .modal-content {
      pointer-events: auto;
    }
  }
}

.fa-key {
  transform: rotateZ(45deg) rotateX(180deg);
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px #353535;
}

::-webkit-scrollbar-thumb {
  background-color: #676767;
  // outline: 1px solid slategrey;
  border-radius: 10px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
input[type='date']::-webkit-clear-button {
  display: none;
}

.datetime-reset-button {
  display: none !important;
}

.v-categoryPage {
  .v-sub-category {
    position: relative;
    background: #333333;
    color: #aeafb0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: #fff;
  color: #fff;
  transition-delay: 9999s;
  transition-property: background-color, color;
  -webkit-transition-delay: 9999s;
  -webkit-transition-property: background-color, color;
}
// input:-webkit-autofill:active  {
//   -webkit-box-shadow: 0 0 0 30px white inset !important;
// }

@media (min-width: 768px) {
  body,
  html {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  body,
  html {
    font-size: 16px;
  }
}
.animate-txt {
  position: relative;
  animation: leftright 3s infinite alternate ease-in-out;
}
@keyframes leftright {
  0%,
  20% {
    transform: translateX(0%);
    left: 0%;
  }
  80%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}
