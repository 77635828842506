.smartbanner-show {
  // margin-top: 100px;
}

.smartbanner-show .smartbanner {
  display: block;
}

/** Default **/
.smartbanner {
  --bgColor: #fff;
  --height: 100px;
  --titleColor: #000;
  --textColor: #333;
  --iconSize: 80px;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: var(--height);
  line-height: var(--height);
  font-family: 'Open Sans', sans-serif;
  background: var(--bgColor);
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  zoom: calc(1 / var(--scaling));
}
.smartbanner-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  height: 100%;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin-block: auto;
  margin-inline: 16px 4px;
  padding: 0;
  text-align: center;
  color: #666;
  text-decoration: none;
  border: 0;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: var(--iconSize);
  height: var(--iconSize);
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #00000040;
  background-repeat: no-repeat;
  background-size: cover;
}

.smartbanner-info {
  // display: inline-block;
  // vertical-align: middle;
  margin: auto 8px;
  display: inline-flex;
  flex-direction: column;
}

.smartbanner-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  color: var(--titleColor);
}
.smartbanner-description {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: var(--textColor);
  &--center {
    text-align: center;
  }
}
.smartbanner-wrapper {
  margin-left: auto;
}
.smartbanner-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 70px;
  height: 30px;
  top: 95px;
  left: 355.92px;
  border-width: 1px;
  border-radius: 30px;
  border: 1px solid #0073de;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  vertical-align: middle;
  color: #0073de;
  margin-right: 16px;
}

.smartbanner-banner {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: #aaa;
}

.smartbanner-button-text {
}

.smartbanner-button-text:active,
.smartbanner-button-text:hover {
}

/** iOS **/
// .smartbanner-ios {
//   background: #f4f4f4;
//   background: linear-gradient(to bottom, #f4f4f4, #cdcdcd);
//   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
//   line-height: 80px;
// }

// .smartbanner-ios .smartbanner-close {
//   border: 0;
//   width: 18px;
//   height: 18px;
//   line-height: 18px;
//   color: #888;
//   text-shadow: 0 1px 0 white;
// }

// .smartbanner-ios .smartbanner-close:active,
// .smartbanner-ios .smartbanner-close:hover {
//   color: #aaa;
// }

// .smartbanner-ios .smartbanner-icon {
//   background: rgba(0, 0, 0, 0.6);
//   background-size: cover;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
// }

// .smartbanner-ios .smartbanner-info {
//   color: #6a6a6a;
//   text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
// }

// .smartbanner-ios .smartbanner-title {
//   color: #4d4d4d;
//   font-weight: bold;
// }

// .smartbanner-ios .smartbanner-button {
//   padding: 0 10px;
//   min-width: 10%;
//   color: #6a6a6a;
//   background: #efefef;
//   background: linear-gradient(to bottom, #efefef, #dcdcdc);
//   border-radius: 3px;
//   box-shadow:
//     inset 0 0 0 1px #bfbfbf,
//     0 1px 0 rgba(255, 255, 255, 0.6),
//     0 2px 0 rgba(255, 255, 255, 0.7) inset;
// }

// .smartbanner-ios .smartbanner-button:active,
// .smartbanner-ios .smartbanner-button:hover {
//   background: #dcdcdc;
//   background: linear-gradient(to bottom, #dcdcdc, #efefef);
// }

// .smartbanner-ios .smartbanner-button-text {
// }

// .smartbanner-ios .smartbanner-button-text:active,
// .smartbanner-ios .smartbanner-button-text:hover {
// }

/** Android **/
// .smartbanner-android {
//   background: #3d3d3d
//     url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
//   box-shadow: inset 0 4px 0 #88b131;
//   line-height: 82px;
// }

// .smartbanner-android .smartbanner-close {
//   border: 0;
//   width: 17px;
//   height: 17px;
//   line-height: 17px;
//   margin-right: 7px;
//   color: #b1b1b3;
//   background: #1c1e21;
//   text-shadow: 0 1px 1px #000;
//   box-shadow:
//     0 1px 2px rgba(0, 0, 0, 0.8) inset,
//     0 1px 1px rgba(255, 255, 255, 0.3);
// }

// .smartbanner-android .smartbanner-close:active,
// .smartbanner-android .smartbanner-close:hover {
//   color: #eee;
// }

// .smartbanner-android .smartbanner-icon {
//   background-color: transparent;
//   box-shadow: none;
// }

// .smartbanner-android .smartbanner-info {
//   color: #ccc;
//   text-shadow: 0 1px 2px #000;
// }

// .smartbanner-android .smartbanner-title {
//   color: #fff;
//   font-weight: bold;
// }

// .smartbanner-android .smartbanner-button {
//   min-width: 12%;
//   color: #d1d1d1;
//   padding: 0;
//   background: none;
//   border-radius: 0;
//   box-shadow:
//     0 0 0 1px #333,
//     0 0 0 2px #dddcdc;
// }

// .smartbanner-android .smartbanner-button:active,
// .smartbanner-android .smartbanner-button:hover {
//   background: none;
// }

// .smartbanner-android .smartbanner-button-text {
//   text-align: center;
//   display: block;
//   padding: 0 10px;
//   background: #42b6c9;
//   background: linear-gradient(to bottom, #42b6c9, #39a9bb);
//   text-transform: none;
//   text-shadow: none;
//   box-shadow: none;
// }

// .smartbanner-android .smartbanner-button-text:active,
// .smartbanner-android .smartbanner-button-text:hover {
//   background: #2ac7e1;
// }

// /** Windows **/
// .smartbanner-windows {
//   background: #f4f4f4;
//   background: linear-gradient(to bottom, #f4f4f4, #cdcdcd);
//   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
//   line-height: 80px;
// }

// .smartbanner-windows .smartbanner-close {
//   border: 0;
//   width: 18px;
//   height: 18px;
//   line-height: 18px;
//   color: #888;
//   text-shadow: 0 1px 0 white;
// }

// .smartbanner-windows .smartbanner-close:active,
// .smartbanner-windows .smartbanner-close:hover {
//   color: #aaa;
// }

// .smartbanner-windows .smartbanner-icon {
//   background: rgba(0, 0, 0, 0.6);
//   background-size: cover;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
// }

// .smartbanner-windows .smartbanner-info {
//   color: #6a6a6a;
//   text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
// }

// .smartbanner-windows .smartbanner-title {
//   color: #4d4d4d;
//   font-weight: bold;
// }

// .smartbanner-windows .smartbanner-button {
//   padding: 0 10px;
//   min-width: 10%;
//   color: #6a6a6a;
//   background: #efefef;
//   background: linear-gradient(to bottom, #efefef, #dcdcdc);
//   border-radius: 3px;
//   box-shadow:
//     inset 0 0 0 1px #bfbfbf,
//     0 1px 0 rgba(255, 255, 255, 0.6),
//     0 2px 0 rgba(255, 255, 255, 0.7) inset;
// }

// .smartbanner-windows .smartbanner-button:active,
// .smartbanner-windows .smartbanner-button:hover {
//   background: #dcdcdc;
//   background: linear-gradient(to bottom, #dcdcdc, #efefef);
// }

// .smartbanner-windows .smartbanner-button-text {
// }

// .smartbanner-windows .smartbanner-button-text:active,
// .smartbanner-windows .smartbanner-button-text:hover {
// }
