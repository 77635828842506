@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
:root {
  font-size: 100%;
  --multiplierFontSize: 0.625;
  --hHeader: 70px;
  --plr: 15px;
  zoom: var(--scaling);
  &.plt-ios {
    @media (min-width: 414px) {
      zoom: 1;
    }
  }
}
ol,
ul,
menu {
  padding-inline-start: 40px;
}
p {
  margin: 0 0 10px;
}
.MuiPickersDay-daySelected {
  background-color: #3f51b5;
}

.modal {
  z-index: 1350;
}
.modal-backdrop {
  z-index: 1349;
}
circle {
  stroke: #fff;
}

.slider-no-scroll .slick-list .slick-track {
  left: 0 !important;
}

.header + div {
  flex-grow: 1;
}
